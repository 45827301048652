<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('jobs')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('jobs')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="job" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('process')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="jobOptions"
                                                         v-model="form.job"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('semester')">
                                                <semesters-selectbox
                                                    :multiple="false"
                                                    :validate-error="errors[0]"
                                                    :setActive="true"
                                                    v-model="form.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-xs-12" v-if="showNumbers">
                                <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="form.number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <b-button @click="start" label="start_process"></b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import JobService from "@/services/JobService";
import LinedTextarea from "../../../components/elements/LinedTextarea";
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox";

export default {
    components: {
        SemestersSelectbox,
        LinedTextarea,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('jobs')
        }
    },
    data() {
        return {
            showSemester: false,
            showNumbers: false,
            form: {
            },
            jobOptions: [
                {value: 'calculate_extra_graduate_courses', text: this.$t('calculate_extra_graduate_courses')},
                {value: 'auto_course_registration', text: this.$t('auto_course_registration')},
                {value: 'auto_repeat_course_registration', text: this.$t('auto_repeat_course_registration')},
                {value: 'auto_irregular_course_registration', text: this.$t('auto_irregular_course_registration')},
                {value: 'create_student_semesters', text: this.$t('create_student_semesters')},
                {value: 'assign_doesnt_have_payment_approval', text: this.$t('assign_doesnt_have_payment_approval')},
                {value: 'assign_doesnt_have_course_registration', text: this.$t('assign_doesnt_have_course_registration')},
            ],
        }
    },
    methods: {
        async start() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let config = {params: this.form};
                let oldNumber=this.form.number;
                if(oldNumber){
                    config.params.number=config.params.number.split(/\n/);
                }

                this.$swal.fire({
                    text: this.$t('are_you_sure_to_start_process'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (this.form.job == 'calculate_extra_graduate_courses') {
                                JobService.calculateExtraGraduateCourses().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'auto_course_registration') {
                                JobService.autoCourseRegistration().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'auto_repeat_course_registration') {
                                JobService.autoRepeatCourseRegistration().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'auto_irregular_course_registration') {
                                JobService.autoIrregularCourseRegistration().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'create_student_semesters') {
                                JobService.createStudentSemesters(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'assign_doesnt_have_payment_approval') {
                                JobService.assignDoesntHavePaymentApproval(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'assign_doesnt_have_course_registration') {
                                JobService.assignDoesntHaveCourseRegistration(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }

                            this.form.number=oldNumber;
                        }
                    })

            }
        },
        callback(response) {
            if(response.data.data.log_id){
                this.$toast.success(this.$t(response.data.message));
                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
            } else if (response.data.data.job_id) {
                if (response.data.message) {
                    this.$toast.success(this.$t(response.data.message));
                } else {
                    this.$toast.success(this.$t('proccess_success'));
                }

                this.$router.push('/job/logs/detail/' + response.data.data.job_id)
            }
        }
    },
    watch: {
        'form.job'(newVal) {

            this.showSemester = false;
            this.showNumbers=false;

            if(newVal=='create_student_semesters'){
                this.showSemester = true;
            }
            else if(newVal=='assign_doesnt_have_payment_approval'){
                this.showSemester = true;
            }
            else if(newVal=='assign_doesnt_have_course_registration'){
                this.showSemester = true;
            }

        }
    },
    computed:{

    }
};
</script>

